/* 通用样式 */
.movies-grid {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  max-width: 1800px;
  padding: 10px;
  overflow: hidden;
}

/* 滑动按钮 */
.nav-button {
  background: rgba(0, 0, 0, 0.6);
  border: none;
  color: #ffffff;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (min-width: 769px) {
  .nav-button.left {
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
  }

  .nav-button.right {
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 768px) {
  .nav-button.left {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }

  .nav-button.left.active {
    display: flex;
  }

  .nav-button.right {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* Movies container */
.movies-container {
  display: flex;
  gap: 12px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 0 10px;
}

/* Movie Card 通用样式 */
.movie-card {
  cursor: pointer;
  background: linear-gradient(135deg, #1c1c1c, #2a2a2a);
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: auto;
}

.movie-card img {
  width: 100%;
  transition: transform 0.4s ease;
}

.movie-card h3 {
  color: #fff;
  font-size: 1em;
  margin: 10px;
  text-align: center;
  transition: color 0.3s ease;
}

.movie-card:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
}

.movie-card:hover img {
  transform: scale(1.05);
}

.movie-card:hover h3 {
  color: #ffcc00;
}

@media (max-width: 480px) {
  .movie-card {
    width: 132px;
    height: 184.81px;
  }

  .movie-card img {
    height: 100%;
    object-fit: cover;
  }

  .nav-button {
    font-size: 18px;
    padding: 5px;
  }



  .close-modal-button {
    padding: 10px 20px;
    font-size: 0.9em;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .movie-card {
    width: 150px;
    height: 210px;
  }

  .movie-card img {
    height: 100%;
    object-fit: cover;
  }

  .nav-button {
    font-size: 20px;
    padding: 8px;
  }
}

@media (min-width: 1025px) {
  .movie-card {
    width: 184px;
    height: 257.59px;
  }

  .movie-card img {
    height: 100%;
    object-fit: cover;
  }
}




@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}




.close-modal-button {
  background: #d9534f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 0.95em;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s ease, transform 0.2s ease;
  align-self: center;
}

.close-modal-button:hover {
  background: #c9302c;
  transform: scale(1.05);
}
.movies-section {
  text-align: center; /* 居中所有文字内容 */
}

.movies-section h2 {
  text-align: center; /* 标题居中 */
  margin-bottom: 20px; /* 可根据需要调整标题下方的间距 */
  font-size: 24px; /* 调整字体大小 */
  font-weight: bold; /* 设置字体加粗 */
}


/* Modal 样式 */
.movie-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85); /* 更深背景 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.4s ease-out;
}

.movie-modal {
  background: none; /* 移除背景 */
  padding: 0;
  max-width: 800px; /* 限制最大宽度 */
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  color: #fff;
  text-align: left; /* 内容左对齐 */
  position: relative;
}

/* 图片样式 */
.movie-modal img {
  width: 100%;
  border-radius: 0; /* 去除圆角 */
  margin: 0;
  position: relative;
}

/* 标题在图片左下角 */
.movie-modal .title-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 2em;
  font-weight: bold;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

/* 标签样式 */
.movie-modal .tags {
  display: flex;
  gap: 10px;
  margin: 15px 20px;
}

.movie-modal .tags span {
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 500;
}

/* 简介样式 */
.movie-modal .description {
  padding: 10px 20px;
  font-size: 1em;
  line-height: 1.6;
  color: #ccc;
}

/* 关闭按钮 */
.movie-modal .close-modal-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #d9534f;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
}

.movie-modal .close-modal-button:hover {
  transform: scale(1.1);
  background: #c9302c;
}

/* 手机端样式 */
@media (max-width: 768px) {
  .movie-modal {
    max-width: 100%;
    padding: 0;
  }

  .movie-modal .title-container {
    font-size: 1.5em;
  }

  .movie-modal .tags {
    flex-wrap: wrap;
    margin: 10px 15px;
  }

  .movie-modal .description {
    padding: 10px 15px;
    font-size: 0.9em;
  }

  .movie-modal .close-modal-button {
    bottom: 15px;
    right: 15px;
    font-size: 0.9em;
    padding: 8px 15px;
  }
}
.movie-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.tag {
  background-color: #444;
  color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
  font-weight: bold;
  display: inline-block;
}
