/* FAQ 样式 */
.faq-section {
  font-family: Arial, sans-serif;
  padding: 30px;
  max-width: 1000px;
  margin: 60px auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: transparent;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

/* 搜索框样式 */
.faq-search {
  width: 60%;
  max-width: 500px;
  min-width: 300px;
  padding: 12px;
  margin: 20px auto;
  border-radius: 6px;
  font-size: 16px;
  display: block;
  border: 1px solid var(--border-color);
  background-color: var(--search-bg-color);
  color: var(--search-text-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.faq-search::placeholder {
  font-style: italic;
  font-size: 14px;
  color: var(--placeholder-color);
}

/* 动态主题变量 */
/* 夜间模式 */
body.night-theme {
  --search-bg-color: #2a2a2a; /* 深灰色背景 */
  --search-text-color: #ffffff; /* 白色文字 */
  --placeholder-color: #aaaaaa; /* 灰色提示文字 */
}

/* 日间模式 */
body.day-theme {
  --search-bg-color: #ffffff; /* 白色背景 */
  --search-text-color: #000000; /* 黑色文字 */
  --border-color: #cccccc; /* 浅灰边框 */
  --placeholder-color: #999999; /* 浅灰提示文字 */
}

/* FAQ 列表样式 */
.faq-list {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.faq-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.faq-question {
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  transition: background-color 0.3s;
}

.faq-toggle {
  font-size: 26px;
  transition: transform 0.3s ease;
}

.faq-item .faq-question.open .faq-toggle {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 20px;
  font-size: 18px;
  line-height: 1.8;
  text-align: left;
}

/* 错误消息 */
.error-message {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  color: var(--placeholder-color);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .faq-search {
    width: 90%;
    max-width: 100%;
  }

  .faq-question {
    font-size: 18px;
    padding: 15px;
  }

  .faq-answer {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .faq-search {
    width: 50%;
  }
}
