/* 全局样式 */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  transition: all 0.3s ease; /* 平滑过渡效果 */
}

/* 夜间模式（默认） */
body.night-theme {
  background-color: #121212; /* 深色背景 */
  color: #ffffff; /* 白色字体 */
}

body.night-theme a {
  color: #ffffff; /* 白色链接 */
}

body.night-theme a:hover {
  color: #ffdd57; /* 悬停时亮黄色 */
}

body.night-theme .hamburger-menu span {
  background-color: #ffffff; /* 汉堡菜单图标白色 */
}

body.night-theme .theme-toggle-button {
  color: #ffffff; /* 按钮文字白色 */
}

/* 日间模式 */
body.day-theme {
  background-color: #ffffff; /* 浅色背景 */
  color: #000000; /* 黑色字体 */
}

body.day-theme a {
  color: #000000; /* 黑色链接 */
}

body.day-theme a:hover {
  color: #007bff; /* 悬停时蓝色 */
}

body.day-theme .hamburger-menu span {
  background-color: #000000; /* 汉堡菜单图标黑色 */
}

body.day-theme .theme-toggle-button {
  color: #000000; /* 按钮文字黑色 */
}

/* 优化后的顶部导航栏样式 */
.top-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; /* 添加左右布局控制 */
  padding: 20px 50px;
  position: sticky;
  z-index: 1000; /* 提升层级，避免被其他元素遮挡 */
  box-sizing: border-box;
  background-color: inherit; /* 动态继承背景 */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* 添加阴影过渡 */
}

/* 添加滚动时的背景色和阴影效果 */
.top-bar.scrolled {
  background-color: rgba(255, 255, 255, 0.9); /* 设置滚动后的背景 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* 滚动时的阴影效果 */
}

/* 响应式调整 */
@media (max-width: 768px) {
  .top-bar {
    padding: 8px 16px; /* 缩小间距适配小屏 */
    flex-wrap: wrap; /* 在小屏幕时允许换行 */
  }
}


.logo-desktop {
  display: block;
}

.logo-mobile {
  display: none;
}

/* 导航栏、主题切换和语言切换一起居中显示 */
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

/* 导航栏样式 */
.navigation {
  display: flex;
  gap: 25px;
}

/* 夜间模式导航链接 */
body.night-theme .navigation a {
  color: #ffffff;
}

body.night-theme .navigation a:hover {
  color: #ffdd57;
}

/* 日间模式导航链接 */
body.day-theme .navigation a {
  color: #000000;
}

body.day-theme .navigation a:hover {
  color: #007bff;
}

.navigation a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 15px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.navigation a:hover {
  transform: translateY(-2px);
}

/* 汉堡菜单按钮 */
.hamburger-menu {
  display: none;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}

.hamburger-menu span {
  display: block;
  width: 25px;
  height: 3px;
  transition: all 0.3s ease;
}

/* 移动端样式 */
@media (max-width: 768px) {
  .logo-desktop {
    display: none;
  }

  .logo-mobile {
    display: block;
    max-height: 50px;
    width: auto;
  }

  .top-bar {
    justify-content: space-between;
  }

  .center-content {
    display: flex;
    align-items: center;
    gap: 10px;
    order: 2; /* 保证主题切换和语言切换在中间 */
  }

  .navigation {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    padding: 10px 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(-20px);
    opacity: 0;
  }

  .navigation.open {
    display: flex;
    transform: translateY(0);
    opacity: 1;
  }

  /* 夜间模式移动端导航背景 */
  body.night-theme .navigation {
    background-color: rgba(0, 0, 0, 0.70);
  }

  /* 日间模式移动端导航背景 */
  body.day-theme .navigation {
    background-color: rgba(255, 255, 255, 0.95);
  }

  .navigation a {
    padding: 10px 20px;
    width: 100%;
    text-align: left;
    border: none;
  }

  .hamburger-menu {
    display: flex;
    order: 3; /* 确保汉堡菜单在最右侧 */
  }

  /* 移动端语言切换器样式 */
  .language-switcher-container {
    margin-top: 0;
    text-align: center;
  }
}

/* 主题切换按钮 */
.theme-toggle-button {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 左右两部分分开 */
  padding: 0 20px;
  background-color: #f8f9fa; /* 示例背景色 */
  height: 60px; /* 示例高度 */
}

.left-content {
  display: flex;
  align-items: center;
  justify-content: center; /* 居中显示导航栏和主题切换 */
  gap: 20px; /* 设置导航栏和主题切换的间距 */
  flex: 1; /* 占据剩余空间让语言切换靠右 */
}

.navigation a {
  text-decoration: none;
  margin: 0 10px; /* 导航项之间的间距 */
  color: #333; /* 文本颜色 */
}

.theme-toggle-button {
  padding: 5px 15px;
  border: none;
  background-color: #007bff00; /* 示例按钮颜色 */
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.language-switcher-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* 靠右对齐 */
}

