/* 背景动画容器 */
.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

/* 星空 canvas */
#star-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  animation: star-twinkle 2s infinite alternate ease-in-out; /* 添加闪烁动画 */
}

/* 流星效果 */
.meteor {
  position: absolute;
  width: 2px;
  height: 150px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), transparent);
  opacity: 0;
  animation: meteor-fall 3s ease-in-out forwards;
}

/* 动态主题控制 */
body.night-theme .background-animation {
  background: #000; /* 深黑色背景 */
}

body.night-theme .meteor {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), transparent);
}

body.day-theme .background-animation {
  background: #f0f8ff; /* 浅蓝色背景，模拟白天的天空 */
}

body.day-theme .meteor {
  background: linear-gradient(to bottom, rgba(200, 200, 200, 0.7), transparent); /* 流星亮度稍暗 */
}

/* 流星划过动画 */
@keyframes meteor-fall {
  0% {
    transform: translateX(0) translateY(0) rotate(45deg) scale(0.8);
    opacity: 0; /* 起始透明度为 0 */
  }
  10% {
    opacity: 0.6; /* 渐入效果 */
  }
  90% {
    opacity: 0.6; /* 保持亮度 */
  }
  100% {
    transform: translateX(-400px) translateY(400px) rotate(45deg) scale(1); /* 缓慢放大 */
    opacity: 0; /* 渐出效果 */
  }
}

/* 星星闪烁动画 */
@keyframes star-twinkle {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
