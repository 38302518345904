/* TV 边框和整体布局样式 */
.tv-frame {
  position: relative;
  width: 90%;
  max-width: 1800px;
  height: 500px;
  background-color: #000;
  border-top: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px auto;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

/* 内部内容容器 */
.tv-content {
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  padding-bottom: 30px;
  color: #fff;
  z-index: 10;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%); /* 自然渐变 */
}

.title, .subtitle {
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7); /* 增加阴影 */
}

/* 标题样式 */
.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  text-align: center;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8), 0 0 15px rgba(0, 0, 0, 0.5);
  animation: pulse-shadow 3s infinite; /* 动态阴影 */
}

.subtitle {
  font-size: 1.5rem;
  margin-top: 10px;
  color: #bbb;
  text-align: center;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.7);
}
@keyframes pulse-shadow {
  0%, 100% {
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8), 0 0 15px rgba(0, 0, 0, 0.5);
  }
  50% {
    text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.9), 0 0 20px rgba(0, 0, 0, 0.6);
  }
}

/* 横向自动滚动效果 */
.hero-banner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
}

.slider {
  display: flex;
  width: calc(200%); /* 双倍宽度，用于容纳两倍的内容，实现无缝滚动 */
  animation: slide-left 20s linear infinite;
}

.slider-image {
  width: auto;
  height: 100%;
  object-fit: cover; /* 保持图片比例 */
}

/* 动画效果 */
@keyframes slide-left {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); } /* 滚动至-50%，展示第二组图片 */
}

/* 响应式设计 */
@media (min-width: 1400px) {
  .tv-frame {
    height: 600px;
    margin-top: 40px;
  }

  .title {
    font-size: 3rem; /* 在大屏幕上增大字体大小 */
  }

  .subtitle {
    font-size: 2rem;
  }
}

@media (max-width: 1024px) {
  .tv-frame {
    height: 400px;
  }
  .title {
    font-size: 2rem; /* 调整平板设备上的字体大小 */
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .slider {
    animation: slide-left 25s linear infinite; /* 在iPad等平板设备上调整动画速度 */
  }
}

@media (max-width: 768px) {
  .tv-frame {
    height: 320px;
    max-width: 100%;
  }
  .title {
    font-size: 1.5rem; /* 调整手机上的字体大小 */
  }

  .subtitle {
    font-size: 1rem;
  }

  .slider {
    animation: slide-left 30s linear infinite; /* 在手机上进一步降低速度，保证流畅性 */
  }
}
