.video-introduction {
  padding: 40px 20px;
  text-align: center;
  background-color: transparent; /* 与主题搭配 */
}

.video-introduction h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: inherit; /* 跟随主题颜色 */
}

.video-introduction p {
  font-size: 18px;
  margin-bottom: 30px;
  color: inherit; /* 跟随主题颜色 */
}

/* 网格布局（电脑端） */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.video-item iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  border: none;
}

.video-item p {
  font-size: 16px;
  margin-top: 10px;
}

/* 单个视频显示（手机端） */
.video-carousel iframe {
  width: 100%;
  max-width: 700px;
  aspect-ratio: 16 / 9;
  border: none;
  margin: 0 auto;
}

.carousel-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.carousel-controls button {
  background-color: transparent;
  border: 1px solid currentColor;
  color: inherit;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.carousel-controls button:hover {
  background-color: rgba(0, 0, 0, 0.1); /* 根据主题微调 */
}

@media (max-width: 768px) {
  .video-grid {
    display: none; /* 隐藏网格布局 */
  }
}
