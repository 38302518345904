/* 容器样式 */
.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
}

/* 包裹公司信息和表单 */
.contact-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

/* 公司信息样式 */
.contact-company-info {
  flex: 1;
  min-width: 280px;
  max-width: 400px;
  padding: 20px;
  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgb(191, 109, 69, 0.5);
  background: rgba(255, 255, 255, 0.95);
  color: var(--text-color);
  line-height: 1.6;
  font-size: 1rem;
}

body.night-theme .contact-company-info {
  background: rgba(34, 34, 34, 0.95);
  color: #fff;
  border-color: #555;
}

/* 表单样式 */
.contact-form {
  flex: 2;
  min-width: 280px;
  max-width: 600px;
  padding: 20px;
  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgb(191, 109, 69, 0.5);
  background: rgba(255, 255, 255, 0.95);
  color: var(--text-color);
  transition: all 0.3s ease;
}

body.night-theme .contact-form {
  background: rgba(34, 34, 34, 0.95);
  border-color: #555;
  color: #fff;
}

/* 表单标题 */
.contact-form h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  color: var(--primary-color);
}

/* 表单内容 */
.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  font-size: 1rem;
  background: rgba(245, 245, 245, 1);
  color: var(--text-color);
  box-sizing: border-box;
  appearance: none;
  line-height: 1.5;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #aaa;
}

.contact-form textarea {
  resize: none;
  min-height: 100px;
}

/* 优化选择框展开行为 */
.contact-form select {
  position: relative;
  width: 100%;
  max-width: 100%; /* 防止宽度超出 */
  box-sizing: border-box;
  overflow: hidden; /* 隐藏超出内容 */
}

.contact-form select option {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.contact-form select:focus {
  outline: none;
}

body.night-theme .contact-form input,
body.night-theme .contact-form select,
body.night-theme .contact-form textarea {
  background: rgba(48, 48, 48, 1);
  color: #fff;
  border-color: #666;
}

/* 提交按钮 */
.contact-form button {
  padding: 12px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: var(--primary-color);
  color: var(--button-text-color);
  font-weight: bold;
  text-transform: uppercase;
  transition: background 0.3s ease, color 0.3s ease;
}

.contact-form button:hover {
  background: var(--primary-hover-color);
}

body.night-theme .contact-form button {
  background: #555;
  color: #fff;
}

/* 响应式优化：适配移动端 */
@media (max-width: 768px) {
  .contact-form {
    padding: 15px;
  }

  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    font-size: 0.95rem;
    padding: 10px;
  }

  .contact-form button {
    font-size: 0.95rem;
    padding: 10px 15px;
  }

  .contact-company-info {
    font-size: 0.95rem;
  }

  .contact-form h2 {
    font-size: 1.6rem;
  }
}

@media (max-width: 600px) {
  .contact-form {
    padding: 10px;
  }

  .contact-form select {
    max-width: 100%; /* 确保选择框宽度适配 */
  }

  .contact-company-info {
    padding: 15px;
  }

  .contact-form h2 {
    font-size: 1.4rem;
  }
}
