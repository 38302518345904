.add-to-home-prompt {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  z-index: 1000;
}

.add-to-home-prompt .share-icon {
  color: #007aff; /* 苹果蓝 */
  margin: 0 5px;
  font-size: 18px; /* 图标大小 */
  vertical-align: middle;
}

.add-to-home-prompt .arrow-animation {
  font-size: 20px;
  animation: bounce 1s infinite;
  margin-top: 5px;
}

.add-to-home-prompt .close-btn {
  margin-top: 10px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
