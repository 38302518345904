/* Footer Styles */
.footer {
  padding: 20px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  background-color: transparent; /* 背景透明，与主题叠合 */
}

.footer-content {
  max-width: 800px;
  margin: 0 auto;
}

.footer p {
  margin: 0;
  padding: 10px 0;
  font-size: 1rem;
  line-height: 1.5;
  color: inherit; /* 跟随主题颜色 */
}

/* 社交链接样式 */
.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}

.social-links a {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-decoration: none;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-links img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: box-shadow 0.3s ease;
}

/* 动态主题样式 */
/* 夜间模式 */
body.night-theme .footer {
  color: #ffffff; /* 深色背景文字 */
}

body.night-theme .footer p {
  color: #bdbdbd;
}

body.night-theme .social-links a {
  color: #ffffff;
}

body.night-theme .social-links a:hover {
  color: #1da1f2; /* Twitter blue */
}

body.night-theme .social-links img:hover {
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.5); /* 白色光效 */
}

/* 日间模式 */
body.day-theme .footer {
  color: #000000; /* 浅色背景文字 */
}

body.day-theme .footer p {
  color: #606060;
}

body.day-theme .social-links a {
  color: #000000;
}

body.day-theme .social-links a:hover {
  color: #007bff; /* 蓝色强调色 */
}

body.day-theme .social-links img:hover {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3); /* 浅色光效 */
}

/* Media Queries for Responsiveness */
@media (max-width: 600px) {
  .footer-content {
    padding: 10px;
  }

  .social-links {
    gap: 10px;
  }

  .social-links a {
    width: 50px;
    height: 50px;
  }

  .footer p {
    font-size: 0.9rem;
  }
}
