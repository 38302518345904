/* Partner Logo Slider 主容器 */
.partner-logo-slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 40px 0;
  background: transparent; /* 完全透明背景 */
  padding: 20px 0;
}

/* 内部容器 */
.partner-logo-slider-inner {
  display: flex;
  gap: 60px; /* logo 间距 */
  animation: slide-left 30s linear infinite; /* 平滑滚动动画 */
  will-change: transform; /* 提升性能 */
}

/* 单个 logo 样式 */
.partner-logo {
  flex-shrink: 0;
  width: 120px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  opacity: 0;
  animation: fadeIn 5s ease forwards; /* 渐入效果 */
}

.partner-logo:hover {
  opacity: 1;
  transform: scale(1.2) rotate(5deg); /* 放大并轻微旋转 */
}

/* logo 图片样式 */
.partner-logo img {
  max-width: 100%;
  max-height: 100%;
}

/* 滚动动画 */
@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* 滚动整个内容长度 */
  }
}

/* 渐入动画 */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* 响应式样式（平板设备） */
@media (max-width: 768px) {
  .partner-logo {
    width: 80px;
    margin: 0;
  }
  .partner-logo-slider-inner {
    animation: slide-left 30s linear infinite; /* 保持动画速度一致 */
  }
}

/* 响应式样式（小屏幕） */
@media (max-width: 480px) {
  .partner-logo-slider {
    padding: 10px 0;
  }
  .partner-logo {
    width: 60px;
    margin: 0;
  }
}
