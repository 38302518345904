/* 公司信息按钮样式 */
.company-info-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px 0; /* 设置基础上下间距 */
}

.info-button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* 初始阴影 */
}

/* 夜间模式 */
body.night-theme .info-button {
  color: #ffffff;
  background: linear-gradient(135deg, #333333, #555555); /* 渐变背景 */
  border: 2px solid #ffffff; /* 保留白色边框 */
}

body.night-theme .info-button:hover {
  background: linear-gradient(135deg, #444444, #666666); /* 悬停时渐变背景 */
  transform: scale(1.05); /* 悬停时放大 */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4); /* 增加阴影 */
  border-color: #f0f0f0; /* 悬停时亮一点的白色边框 */
}

body.night-theme .info-button:active {
  transform: scale(0.95); /* 按下时缩小 */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); /* 减少阴影 */
  border-color: #dddddd; /* 按下时更深的白色边框 */
}

/* 日间模式 */
body.day-theme .info-button {
  color: #000000;
  background: linear-gradient(135deg, #e0e0e0, #f0f0f0); /* 浅灰色渐变背景 */
  border: 2px solid #000000; /* 黑色边框 */
}

body.day-theme .info-button:hover {
  background: linear-gradient(135deg, #d0d0d0, #e0e0e0); /* 悬停时变浅的背景 */
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4); /* 增加阴影 */
  border-color: #333333; /* 悬停时更深的黑色边框 */
}

body.day-theme .info-button:active {
  transform: scale(0.95);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-color: #666666; /* 按下时较浅的灰色边框 */
}

/* 为较大屏幕（桌面端）增加按钮尺寸和间距 */
@media (min-width: 1024px) {
  .company-info-section {
    gap: 40px; /* 增加按钮之间的间距 */
    margin: 60px 0; /* 增加顶部和底部间距 */
  }

  .info-button {
    padding: 15px 30px; /* 增大按钮内边距 */
    font-size: 1.2rem; /* 增大字体 */
    border-radius: 12px; /* 适当增大按钮圆角 */
  }
}

/* 模态框样式 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%; /* 自适应宽度 */
  width: 100%;
  max-height: 80%; /* 自适应高度 */
  overflow-y: auto; /* 当内容过多时可滚动 */
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.2);
  animation: fadeIn 0.3s ease-in-out; /* 淡入动画 */
}

/* 夜间模式 */
body.night-theme .modal-content {
  background-color: #222222;
  color: #ffffff;
}

/* 日间模式 */
body.day-theme .modal-content {
  background-color: #f9f9f9;
  color: #000000;
}

.license-image {
  width: 100%;
  max-width: 300px;
  margin-top: 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s;
}

/* 夜间模式 */
body.night-theme .close-button {
  color: #ffffff;
}

body.night-theme .close-button:hover {
  color: #ff6666;
}

/* 日间模式 */
body.day-theme .close-button {
  color: #000000;
}

body.day-theme .close-button:hover {
  color: #ff0000;
}

/* 模态框动画 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
