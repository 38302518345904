/* 重置样式 */
body {
  margin: 0;
  padding: 0;
  line-height: 1.6;
  overflow-x: hidden;
  transition: background-color 0.3s ease, color 0.3s ease; /* 添加平滑过渡效果 */
}

/* 其他全局样式 */
.hero-banner .tv-frame {
  position: relative;
  max-width: 100%;
}

.hero-banner img {
  width: 130%;
  height: auto;
  animation: slide-left 30s linear infinite;
}

.movie-card {
  flex: 0 0 auto;
  width: 184px;
  height: 257px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: #222;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

/* 响应式样式 */
@media (max-width: 768px) {
  .header-nav {
    flex-direction: column;
    gap: 10px;
  }

  .hero-banner {
    margin: 10px auto;
    border-radius: 10px;
  }
}

@media (max-width: 480px) {
  .header {
    flex-direction: column;
    padding: 10px;
  }

  .movie-card {
    width: 132px;
    height: 185px;
  }
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: background 0.3s ease;
}

.nav-button.left {
  left: 10px;
}

.nav-button.right {
  right: 10px;
}

.nav-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-30%);
  }
}
