/* 社交分享容器 */
.social-share {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column-reverse; /* 图标从下到上排列 */
  align-items: flex-end; /* 保证所有图标向右对齐 */
  gap: 15px; /* 图标之间的间距 */
}

/* 主分享图标按钮 */
.main-share-button {
  background-color: transparent;
  color: var(--button-color, #007bff);
  border: none;
  width: 60px; /* 主图标宽度 */
  height: 60px; /* 主图标高度 */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 28px; /* 主图标大小 */
  transition: color 0.3s ease, transform 0.2s;
}

.main-share-button:hover {
  color: var(--button-hover-color, #0056b3);
  transform: scale(1.1);
}

/* 分享选项容器 */
.share-options {
  display: flex;
  flex-direction: column; /* 垂直排列图标 */
  gap: 15px;
  opacity: 0;
  pointer-events: none; /* 初始不可点击 */
  transform: translateY(20px); /* 初始位置在底部 */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.social-share.expanded .share-options {
  opacity: 1;
  pointer-events: auto; /* 展开后可点击 */
  transform: translateY(0); /* 移动到目标位置 */
}

/* 单个分享图标 */
.share-button {
  background-color: transparent;
  color: var(--icon-color, #333);
  border-radius: 50%; /* 圆形外观 */
  width: 60px; /* 固定宽度 */
  height: 60px; /* 固定高度 */
  display: flex;
  align-items: center; /* 图标垂直居中 */
  justify-content: center; /* 图标水平居中 */
  font-size: 24px; /* 图标大小 */
  text-align: center;
  text-decoration: none;
  transform: translateY(20px); /* 初始位置 */
  opacity: 0; /* 初始透明 */
  animation: none; /* 默认无动画 */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* 确保内部图标（SVG 或字体图标）一致大小 */
.share-button svg,
.share-button i {
  width: 50%; /* 相对于父容器的宽度 */
  height: 50%; /* 相对于父容器的高度 */
  display: block;
}

/* 展开时的图标动画 */
.social-share.expanded .share-button {
  animation: fadeInIcon 0.5s ease forwards;
}

/* 每个图标的动画延迟 */
.social-share.expanded .share-button:nth-child(1) {
  animation-delay: 0.4s;
}

.social-share.expanded .share-button:nth-child(2) {
  animation-delay: 0.3s;
}

.social-share.expanded .share-button:nth-child(3) {
  animation-delay: 0.2s;
}

.social-share.expanded .share-button:nth-child(4) {
  animation-delay: 0.1s;
}

/* 隐藏时的图标动画 */
.social-share:not(.expanded) .share-button {
  animation: fadeOutIcon 0.5s ease forwards;
}

.social-share:not(.expanded) .share-button:nth-child(1) {
  animation-delay: 0.1s;
}

.social-share:not(.expanded) .share-button:nth-child(2) {
  animation-delay: 0.2s;
}

.social-share:not(.expanded) .share-button:nth-child(3) {
  animation-delay: 0.3s;
}

.social-share:not(.expanded) .share-button:nth-child(4) {
  animation-delay: 0.4s;
}

/* 图标展开动画 */
@keyframes fadeInIcon {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 图标隐藏动画 */
@keyframes fadeOutIcon {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(20px); /* 回到底部 */
    opacity: 0;
  }
}

/* 夜间模式 */
body.night-theme .main-share-button {
  color: #fff;
}

body.night-theme .share-button {
  color: white;
}


