.language-switcher-container select {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    border: 2px solid var(--border-color, #ddd); /* 动态边框 */
    border-radius: 6px; /* 圆角更自然 */
    background-color: var(--background-color, transparent); /* 动态背景 */
    color: var(--text-color, #000); /* 动态字体颜色 */
    cursor: pointer;
    transition: all 0.3s ease; /* 平滑过渡 */
    box-shadow: var(--box-shadow, none); /* 动态阴影 */
}

/* 聚焦状态 */
.language-switcher-container select:focus {
    outline: none;
    border-color: var(--focus-border-color, #007bff);
    box-shadow: 0 0 8px var(--focus-border-color, #007bff); /* 增加阴影效果 */
}

/* 下拉菜单样式 */
.language-switcher-container select::-webkit-dropdown-menu {
    background-color: var(--dropdown-background, #ffffff);
    color: var(--dropdown-text-color, #000);
    border-radius: 6px;
    border: 1px solid var(--dropdown-border-color, #ccc);
}

/* 白天模式样式 */
body.day-theme {
    --text-color: #000000; /* 黑色字体 */
    --border-color: #ccc; /* 浅灰边框 */
    --focus-border-color: #007bff; /* 蓝色聚焦边框 */
    --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* 浅色阴影 */
    --dropdown-background: #ffffff; /* 下拉菜单白色背景 */
    --dropdown-text-color: #000000; /* 下拉菜单字体黑色 */
    --dropdown-border-color: #ddd; /* 下拉菜单边框颜色 */
}

/* 夜间模式样式 */
body.night-theme {
    --background-color: rgba(0, 0, 0, 0.5); /* 半透明深灰背景 */
    --text-color: #ffffff; /* 白色字体 */
    --border-color: rgba(255, 255, 255, 0.4); /* 半透明白色边框 */
    --focus-border-color: #ff8800; /* 橙色聚焦边框 */
    --box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4); /* 深色阴影 */
    --dropdown-background: rgba(51, 51, 51, 0.95); /* 下拉菜单半透明深灰背景 */
    --dropdown-text-color: #ffffff; /* 下拉菜单字体白色 */
    --dropdown-border-color: rgba(255, 255, 255, 0.2); /* 下拉菜单半透明边框 */
}
